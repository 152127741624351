import {
  Box,
  Card,
  Loader,
  Page,
  WixDesignSystemProvider,
  Button,
  Layout,
  Cell,
  Text,
  ToggleSwitch,
  FormField,
  SegmentedToggle,
  LinearProgressBar,
  Notification,
  SectionHelper,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";

import "./App.css";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const ADDRESS_ENFORCEMENT_TYPE = {
  NONE: {
    value: "NONE",
    label: "None",
    icon: <Icons.LockUnlocked />,
  },
  LOOSE: {
    value: "LOOSE",
    label: "Loose",
    icon: <Icons.LockLocked />,
  },
  STRICT: {
    value: "STRICT",
    label: "Strict",
    icon: <Icons.LockLocked color="#1D8649" />,
  },
};

const VAT_ENFORCEMENT_TYPE = {
  NONE: {
    value: "NONE",
    label: "None",
    icon: <Icons.LockUnlocked />,
  },
  LOOSE: {
    value: "LOOSE",
    label: "Loose",
    icon: <Icons.LockLocked />,
  },
  STRICT: {
    value: "STRICT",
    label: "Strict",
    icon: <Icons.LockLocked color="#1D8649" />,
  },
};

const PLAN_LIMITS: any = {
  basic: 10,
  lite: 100,
  plus: 500,
  pro: 1500,
};

const CONTENT = ["Everything in Standard +", "Validate 1500 checkouts"];

const INTERVAL = 4000;

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [credits, setCredits] = React.useState({} as any);
  const [showSaved, setShowSaved] = React.useState(false);
  const [config, setConfig] = React.useState({
    isEnabled: false,
    address: {
      enforcementType: ADDRESS_ENFORCEMENT_TYPE.NONE.value,
    },
    vat: {
      enforcementType: VAT_ENFORCEMENT_TYPE.NONE.value,
    },
  });
  const [usageNumber, setUsageNumber] = React.useState(0);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);

  const isFree = instanceData?.instance?.isFree !== false;

  const [index, setIndex] = React.useState<number>(0);
  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
    }, INTERVAL);

    return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getConfig();
    getCredits();
  }, []);

  const BASE_URL = `https://certifiedcode.wixsite.com/optimizer/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function saveConfig() {
    fetch(BASE_URL + "/config", {
      method: "POST",
      body: JSON.stringify(config),
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        setShowSaved(true);
      })
      .catch(() => {});
  }

  function getConfig() {
    fetch(BASE_URL + "/config", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.address) {
          setConfig(data);
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const isFree = data.instance.isFree === false;
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(isFree);
        setIsUpgradeBannerOpen(!isFree);
      })
      .catch(() => {});
  }

  function getCredits() {
    fetch(BASE_URL + "/credits", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setCredits(data);
      })
      .catch(() => {});
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=28083b21-7176-4860-9067-40661a55e6f1&redirectUrl=https://certifiedcode.wixsite.com/optimizer/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const upgradeUrl = `https://www.wix.com/apps/upgrade/${"28083b21-7176-4860-9067-40661a55e6f1"}?appInstanceId=${
    instanceData?.instance?.instanceId
  }`;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification show={showSaved} autoHideTimeout={3000}>
        <Notification.TextLabel>
          Your changes have been saved successfully
        </Notification.TextLabel>
      </Notification>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>
          {`Upgrade to unlock "${CONTENT[index]}"`}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
          Upgrade
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Page
        height="100vh"
        className={`${isUpgradeBannerOpen ? "App-header-notification" : ""}`}
      >
        <Page.Header
          title={"Optimizer: Checkout Validations"}
          subtitle={"Control your checkout experience with Optimizer."}
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              {isUpgraded && (
                <Button
                  prefixIcon={<Icons.Reviews />}
                  skin="inverted"
                  as="a"
                  href="https://bridget.reviews.certifiedcode.us/28083b21-7176-4860-9067-40661a55e6f1"
                  target="_blank"
                >
                  Write a review
                </Button>
              )}
              <Button
                skin="premium"
                prefixIcon={<Icons.PremiumFilled />}
                as="a"
                href={upgradeUrl}
                target="_blank"
              >
                {isUpgraded ? "Manage Plan" : "Increase Validation Limit"}
              </Button>
              <Button
                prefixIcon={<Icons.Check />}
                skin="standard"
                onClick={() => {
                  saveConfig();
                }}
              >
                Publish Changes
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Layout>
            <Cell span={8}>
              <Card>
                <Card.Header title="Manage Validations"></Card.Header>
                <Card.Divider />
                <Card.Content>
                  <Layout cols={1}>
                    <Box direction="vertical" gap="12px">
                      <FormField label="Address validations">
                        <Box>
                          <SegmentedToggle
                            selected={config.address.enforcementType}
                            onClick={(_, value) =>
                              setConfig({
                                ...config,
                                address: {
                                  enforcementType: value,
                                },
                              })
                            }
                          >
                            {Object.values(ADDRESS_ENFORCEMENT_TYPE).map(
                              (type) => (
                                <SegmentedToggle.Button
                                  prefixIcon={type.icon}
                                  value={type.value}
                                >
                                  {type.label}
                                </SegmentedToggle.Button>
                              )
                            )}
                          </SegmentedToggle>
                        </Box>
                      </FormField>
                      {config.vat.enforcementType &&
                        config.vat.enforcementType !==
                          VAT_ENFORCEMENT_TYPE.NONE.value && (
                          <SectionHelper
                            title="Make sure you've added a custom field to your checkout page to capture the VAT number."
                            actionText="Learn more"
                            onAction={() => {
                              window.open(
                                "https://support.wix.com/en/article/wix-stores-customizing-the-checkout-page#customizing-the-info-collected-from-customers",
                                "_blank"
                              );
                            }}
                          >
                            Without a custom field, the VAT number will not be
                            validated.
                          </SectionHelper>
                        )}
                      <FormField label="VAT validations">
                        <Box>
                          <SegmentedToggle
                            selected={config.vat.enforcementType}
                            onClick={(_, value) =>
                              setConfig({
                                ...config,
                                vat: {
                                  enforcementType: value,
                                },
                              })
                            }
                          >
                            {Object.values(VAT_ENFORCEMENT_TYPE).map((type) => (
                              <SegmentedToggle.Button
                                prefixIcon={type.icon}
                                value={type.value}
                              >
                                {type.label}
                              </SegmentedToggle.Button>
                            ))}
                          </SegmentedToggle>
                        </Box>
                      </FormField>
                    </Box>
                  </Layout>
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={4}>
              <Card>
                <Card.Header title="Available credits" />
                <Card.Divider />
                <Card.Content>
                  <Layout cols={1}>
                    {instanceData?.instance?.billing?.packageName ===
                    "unlimited" ? (
                      <Box direction="vertical" gap="12px">
                        <LinearProgressBar
                          skin="success"
                          showProgressIndication
                          value={100}
                        />
                        <Text skin="success">
                          You are on Unlimited! No need to worry about usage.
                        </Text>
                      </Box>
                    ) : (
                      <Box direction="vertical" gap="12px">
                        <LinearProgressBar
                          skin="neutral"
                          value={
                            (credits?.credits?.remaining || 0) /
                            PLAN_LIMITS[
                              instanceData?.instance?.billing?.packageName ||
                                "basic"
                            ]
                          }
                        />
                        <Text skin="success">
                          {PLAN_LIMITS[
                            instanceData?.instance?.billing?.packageName ||
                              "basic"
                          ] - credits?.credits?.remaining || 0}{" "}
                          out of{" "}
                          {
                            PLAN_LIMITS[
                              instanceData?.instance?.billing?.packageName ||
                                "basic"
                            ]
                          }{" "}
                          validations used
                        </Text>
                      </Box>
                    )}
                  </Layout>
                </Card.Content>
              </Card>
            </Cell>
            {/* <Cell span={12}>
              <BC.Offers appId="28083b21-7176-4860-9067-40661a55e6f1" />
            </Cell>
            <Cell span={12}>
              <EmptyState
                theme="page"
                title="More content coming soon"
                subtitle="In the meantime, you can check out our other apps"
              >
                <TextButton
                  as="a"
                  href="https://www.wix.com/app-market/developer/certified-code"
                  target={"_blank"}
                  prefixIcon={<Icons.AppMarket />}
                >
                  View App Collections
                </TextButton>
              </EmptyState>
            </Cell> */}
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
